
.quill > * {
  border-color: inherit !important;
  color: inherit !important;
  
}
.quill > .ql-toolbar {
  /* border radius of the toolbar */
  border-radius: 10px 10px 0 0;
  
}
.quill > .ql-container {
  /* border radius of the container and for font size*/


  min-height: 200px;
}
.ql-toolbar.ql-snow .ql-picker-label {
  color: inherit !important;
  opacity: 0.76;
}
.ql-snow .ql-picker {
  color: inherit !important;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
  /* for placeholder */
  color: inherit;
}
.ql-snow.ql-toolbar button svg {
  opacity: 0.76;
  color: currentColor;
}
.ql-snow .ql-stroke {
  /* for the border of the editor */
  stroke: currentColor !important;
}
.ql-snow .ql-fill {
  /* for the bg color */
  fill: currentColor !important;
}
.ql-picker-item {
  /* for dropdown */
  color: #444 !important;
}
.ql-container .ql-snow{
  min-height: 350px;
  }
  .rdrDayNumber span {
  color: #575d6b !important;
  }



/* .recharts-surface{
  

  padding-top: 50px;
} */